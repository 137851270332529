import { render, staticRenderFns } from "./friday.vue?vue&type=template&id=254c0040&scoped=true&"
import script from "./friday.vue?vue&type=script&lang=js&"
export * from "./friday.vue?vue&type=script&lang=js&"
import style0 from "./friday.vue?vue&type=style&index=0&id=254c0040&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254c0040",
  null
  
)

export default component.exports